<template>
  <div class="container">
    <div v-if="!loading">
      <div class="title">{{ title }}</div>
      <!--<div v-if="create_time" class="create-time">{{ moment(create_time).format("YYYY-MM-DD HH:mm") }}</div>-->
      <div class="content" v-html="content"></div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { Indicator } from "mint-ui";
import { getArticlePost } from "./api";

export default {
  name: "post",
  data() {
    return {
      moment,
      title: "",
      create_time: "",
      content: "",
      loading: true,
    };
  },
  async created() {
    this.loading = true;
    Indicator.open("加载中");
    let id = this.$route.query.id;
    let res = await getArticlePost({ id });
    if (res) {
      this.title = res.title;
      this.create_time = res.create_time;
      this.content = res.content;
      this.loading = false;
      Indicator.close();
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 15px;
  background-color: #F7F5EF;
  .title {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0 25px;
    color: #9B6A31;
  }
  .content{
      color: rgba(0,0,0,0.6);
  }
  .create-time {
    font-size: 13px;
    color: #999;
    margin: 10px 0;
  }
}
</style>